import React from 'react';
import './header.css';

// Import images
import McGillLogo from '../../assets/mcgill-university-3-logo-png-transparent.png';
import UofTLogo from '../../assets/University_of_Toronto-Logo.png';
import ConcordiaLogo from '../../assets/concordia-logo.webp';
import UBCLogo from '../../assets/ubc-logo.png';
import UdMLogo from '../../assets/1200px-Universite_de_Montreal_logo.svg.png';
import ETSLogo from '../../assets/Logo_ETS_Noir_TypoNoire_D_EN.png';

import GuidePreview from '../../assets/guide_screenshot_table.png'


const Header = () => {
  return (
    <div className='header section__padding' id='header'>
      <div className='header-top-row'>
        <div className='header-content'>
          <h1>
            Tu pars etudier au Canada? Voici toute l'information dont tu as besoin.
          </h1>
          <h2>
            Un guide de départ avec toute l'information nécessaire.
          </h2>
          
          <a href='#get-guide-button'>
          <button className="buy-button header-button">
            Télécharger le guide
          </button>
        </a>
        
        </div>

        <div className='header-logos'>
          <img src={McGillLogo} alt="McGill University" width="242" />
          <img src={UdMLogo} alt="Uinversité de Montréal" width="276.205" />
          <img src={ConcordiaLogo} alt="Concordia University" width="265.266px" />
          <img src={UBCLogo} alt="University of British Columbia" width="211.939"/>
          <img src={UofTLogo} alt="University of Toronto" width="318.64" />
          <img src={ETSLogo} alt="Ecole technologique superieure" width="211.939" />
        </div>

      </div>
      
      <div className='header-content-summary-section'>
        <div className='header-content-summary'>
          <h3>
            Un guide détaillé qui comprend toutes les informations importantes pour réussir son départ:
          </h3>
          <ul>
            <li>Quelle école choisir?</li>
            <li>Quand et où postuler?</li>
            <li>Combien de temps faut-il s'y prendre à l'avance?</li>
            <li>Quel examen d'anglais passer? Quand faut il passer l'examen?</li>
            <li>Puis-je postuler à des bourses?</li>
            <li>Combien coûtent les programmes d'études? Le visa? Une année au Canada?</li>
            <li>Quelles notes faut-il avoir?</li>
            <li>Où et comment soumettre une demande de visa?</li>
            <li>Que faire en arrivant?</li>
          </ul>
        </div>

        <div className='guide-preview'>
          <img src={GuidePreview} alt="Guide Preview" width="450"/>
        </div>

      </div>
      
      
    </div>
  )
}

export default Header