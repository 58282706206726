      const Article = (props) => (
        
        <div className='article'>
          <div className='article-title'>
            <h1>{props.title}</h1>
          </div>
          <div className='article-content'>
            {props.content.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>
        </div>
        );

  export default Article;



  