import { Navbar, Footer} from '../containers';
import Article from '../components/article/article';


function TermsOfService() {

    return (
      <div className="Licenses">
        <Navbar />
        <Article 
          title="Conditions Générales d'Utilisation" 
          content={[

            "1. Introduction",
            "Bienvenue sur Etudes-Canada.com. En accédant à notre site web et en utilisant nos services, vous acceptez de respecter et d'être lié par les termes et conditions suivants.",

            "2. Droits de Propriété Intellectuelle",
            "Le contenu, la mise en page, le design, les données, les graphiques et les produits sur notre site web sont protégés par les lois sur la propriété intellectuelle et appartiennent à Etudes-Canada.com ou ont été autorisés à l'utilisation par les détenteurs de ces droits.",

            "3. Utilisation du Site Web",
            "Ce site et son contenu sont destinés aux clients de Etudes-Canada.com. Vous ne pouvez pas utiliser ce site à des fins illégales ou non autorisées.",

            "4. Obligations de l'Utilisateur",
            "Vous acceptez de fournir des informations exactes et complètes lorsque nécessaire et êtes responsable de la confidentialité de vos détails de compte.",

            "5. Politique de Confidentialité",
            "Notre politique de confidentialité, qui détaille comment nous utiliserons vos informations, peut être trouvée sur notre site web. En utilisant ce site, vous consentez au traitement décrit et garantissez que toutes les données fournies par vous sont exactes.",

            "6. Limitation de Responsabilité",
            "Etudes-Canada.com ne sera pas responsable des dommages indirects, spéciaux, accessoires ou consécutifs découlant de l'utilisation ou de l'impossibilité d'utiliser nos services.",

            "7. Modifications des Termes",
            "Nous nous réservons le droit de modifier ces termes à tout moment. Votre utilisation continue du site après de telles modifications constitue votre acceptation des nouveaux termes.",

            "8. Loi Applicable",
            "Ces termes seront régis et interprétés conformément aux lois de [Votre Pays], sans considération de ses dispositions de conflit de lois.",

            "9. Nous Contacter",
            "Pour toute question concernant ces termes, veuillez nous contacter à hello@alexandresaussier.com"

]}
        />          
        <Footer />
      </div>
    )
  }
  
  export default TermsOfService