import React from 'react';
import './footer.css';
import Logo from '../../assets/etudes-canada-logo.png'



const Footer = () => {
  return (
    <div className='footer section__padding'>
     
     <div className='footer-section'>
        <div className='footer-logo'>
          <img src={Logo} alt="Etudes-Canada" />
          <p>Etudes-Canada</p>
          
        </div>

        <div className='footer-copyright'>
          <p>Le meilleur guide pour partir etudier au Canada</p>
          <p>Copyright © 2024 - All rights reserved</p>

        </div>

      </div>
      <div className='footer-section'>
        <h1>Liens</h1>
        <ul>
          <li><a href='/licenses'>Licenses</a></li>
          <li><a href='/support'>Support</a></li>
        </ul>
      </div>
      <div className='footer-section'>
      <h1>Légal</h1>
      <ul>
          <li><a href='/terms-of-service'>Conditions Générales d'Utilisation</a></li>
          <li><a href='/privacy-policy'>Politique de Confidentialité</a></li>
        </ul>
      </div>
      <div className='footer-section'>
      <h1>Plus</h1>
      <ul>
          <li><a href='https://twitter.com/EtudesCanada1' target="_blank" rel="noopener noreferrer">X</a></li>
        </ul>
      </div>
      <div className='footer-section'>
      <h1>Blog</h1>
      <ul>
          <li><a href='/blog/préparer-son-enfant-aux-études-au-canada'>Préparer son enfant aux études au Canada</a></li>
          <li><a href='/blog/guide-des-visas'>Guide des visas d'études au Canada</a></li>
        </ul>
      </div>
    </div>
  )
}

export default Footer