import { Navbar, Footer} from '../containers';
import Article from '../components/article/article';


function PrivacyPolicy() {

    return (
      <div className="Licenses">
        <Navbar />
        <Article 
          title="Politique de Confidentialité pour Etudes-Canada.com" 
          content={[

            "1. Introduction",
            "Cette politique de confidentialité explique comment Etudes-Canada.com collecte, utilise, et protège les informations personnelles des utilisateurs.",

            "2. Collecte d'Informations",
            "Nous collectons des informations lorsque vous utilisez notre site, notamment lors de l'inscription, de l'achat de guides, ou de toute interaction avec notre service clientèle.",

            "3. Utilisation des Informations",
            "Les informations recueillies peuvent être utilisées pour améliorer nos services, traiter les transactions, envoyer des e-mails périodiques, ou répondre à des demandes de service client.",

            "4. Protection de vos Informations",
            "Nous mettons en œuvre une variété de mesures de sécurité pour maintenir la sécurité de vos informations personnelles.",

            "5. Divulgation à des Tiers",
            "Nous ne vendons, échangeons ni transférons vos informations personnelles identifiables à des tiers. Cela ne comprend pas les tiers de confiance qui nous aident à exploiter notre site web, à condition qu'ils acceptent de garder ces informations confidentielles.",

            "6. Consentement",
            "En utilisant notre site, vous consentez à notre politique de confidentialité.",

            "7. Modifications de la Politique de Confidentialité",
            "Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Les modifications seront affichées sur cette page.",

            "8. Contact",
            "Pour toute question relative à cette politique de confidentialité, veuillez nous contacter à hello@alexandresaussier.com",

]}
        />          
        <Footer />
      </div>
    )
  }
  
  export default PrivacyPolicy