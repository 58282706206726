import { Navbar, Footer, BuySection } from '../../containers';
import Article from '../../components/article/article';
import './blog-post.css'

import { useEffect } from 'react';
import { getAnalytics, logEvent } from 'firebase/analytics';



function GuideDesVisas() {

  //To check how many people have viewed this page in Google Analytics
  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Préparez votre enfant pour les études au Canada',
    });
  }, []);

    return (
      <div className="blog-post">
        <Navbar />
        <div className='blog-post-content'>
          <title>Guide des visas d'etudes au Canada: ce que vous devez savoir</title>
          <Article 
            title="Guide des visas d'etudes au Canada: ce que vous devez savoir" 
            content={[

              "En tant qu'étudiant étranger, il vous sera nécessaire de faire une demande de visa avant de partir étudier au Canada, afin de séjourner légalement sur le territoire le temps de vos études. Rassurez-vous; bien qu'intimidant, le processus de demande de visa d'étude Canadien est relativement simple et cet article vous aidera à vous y retrouver.",
              "Tout d'abord, il est important de savoir que vous devrez probablement faire des demandes d'autorisation d'études au Canada auprès de deux entitées. Le Canada est divisé en provinces qui, comme pour les états aux Etats-Unis, disposent d'une importante autonomie. Au Québec, par exemple, il vous faudra demander le Certificat d'Acceptation du Québec avec lequel vous pourez ensuite soumettre votre demande de permis d'études au niveau fédéral.",
              "Les critères d'admission au Canada sont ceux à quoi ont pourrait s'attendre: prouver que vous êtes inscrit dans un établissement d'enseignement reconnu par l'état, prouver que vous disposez de moyens suffisants pour subvenir à vos études, être en bonne santé (examen médical peut être requis). Attendez-vous à fournir les documents nécessaires, en lien avec les sujets mentionnés, pour obtenir votre visa canadien.",
              "Si vous souhaitez étudier au Québec (pour plus de 6 mois), vous devrez en premier lieu et après avoir été accepté dans une université québecoise, formuler votre demande pour le Certificat d'Acceptation du Québec (CAQ). Le CAQ, contrairement au visa fédéral, est régis par le ministère de l'immigration du québec et vous enregistre auprès de celui-ci. Le CAQ coûte $124 pour les étudiants internationaux (non remboursable)",
              "Lorsque vous préparerez vos demandes, asssurez de bien être précis et clair, ce qui évitera les allers-retours entre l'administration et vous, et vous fera perdre du temps. Attnedez-vous à recevoir une réponse à votre demande de CAQ dans les 20 à 30 jours et comptez environ 1 à 2 mois pour le visa fédéral.",
              "Si vous ne recevez pas votre visa à temps pour le début de l'année scolaire, vous pourrez quand-même rentrer sur le territoire avec un AVE (Autorisation de Voyage Electronique pour touristes). Lors de la réception de votre visa, vous devrez cependant sortir du territoire et re-rentrer pour recevoir votre visa à la frontière.",
              "En conclusion, il vous faudra faire deux demandes afin de recevoir votre visa d'études: la première au niveau provincial (exemple: Certificat d'Acceptation au Québec), puis ensuite au niveau fédéral (permis d'études). Le délai total est environ de 2 à 3 mois. Assurez-vous de remplir vos demandes avec précision et préparez-vous à fournir divers documents."
              
            ]}
          />
          <p> Si vous souhaitez plus d'informations à ce sujet, et à propos des études au Canada en général, Etudes-Canada.com vous propose <a href='/home'>un guide complet </a>qui vous permet de tout savoir sur les procédures nécessaires pour partir étudier au canada: classement des universités, dates limites pour postuler, examens d'anglais requis, visas requis, délais de traitement et plus encore.</p>
        </div>

        <BuySection />
        <Footer />
      </div>
    )
  }
  
  export default GuideDesVisas