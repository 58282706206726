import { Navbar, Footer, BuySection} from '../../containers';
import './blog-post.css'
import { getAnalytics, logEvent } from 'firebase/analytics';

import { useEffect } from 'react';




function CampusHecMontreal() {

  //To check how many people have viewed this page in Google Analytics
  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: "HEC Montréal : Un Campus Innovant et Durable au Cœur de Montréal",
    });
  }, []);

    return (
      <div className="blog-post">
        <title>HEC Montréal : Un Campus Innovant et Durable au Cœur de Montréal</title>

        <Navbar />
        <div className='blog-post-content'>
          <div className='article'>
            <div className='article-title'>
              <h1>HEC Montréal : Un Campus Innovant et Durable au Cœur de Montréal</h1>
            </div>
            <div className='article-content'>
              <p> 
               HEC Montréal se distingue non seulement par son excellence académique, mais aussi par son campus novateur qui reflète les valeurs de durabilité et d'inclusion. L'école accueille sa communauté au sein de trois bâtiments avant-gardistes, chacun conçu pour encourager un environnement d'apprentissage dynamique à la pointe de l'éducation en gestion.
              </p>

              <h2>Un Campus aux Fondations Durables</h2>
              <p>
               Le campus de HEC Montréal se veut un laboratoire vivant où les approches et actions en matière de développement durable sont encouragées. Chaque structure est un modèle de durabilité, visant à faire de HEC Montréal un leader en matière de développement durable, de responsabilité sociale des entreprises (RSE) et d'éthique. Ces efforts se manifestent à travers l'obtention de certifications prestigieuses et l'adoption de pratiques éco-responsables dans la conception et la gestion quotidienne du campus.
              </p>

              <h2>L'Innovation au Cœur de la Ville</h2>
              <p>
              L'ouverture du bâtiment Hélène Desmarais en plein cœur du quartier d'affaires de Montréal marque un nouveau chapitre pour HEC Montréal. Ce bâtiment, symbole de modernité, fonctionnalité et durabilité, rapproche l'école de sa clientèle d'affaires, facilitant ainsi le partage de connaissances et la collaboration. La certification LEED Or, une conception favorisant la biodiversité, et la promotion des transports actifs ne sont que quelques exemples de la façon dont ce bâtiment incarne les principes du développement durable dès ses premières esquisses.
              </p>

              <h2>Un Héritage d'Excellence et d'Innovation</h2>
              <p>
               Le campus principal, situé au 3000 Côte-Sainte-Catherine, a été inauguré en 1996 et est devenu un symbole de l'architecture institutionnelle. Le design du bâtiment, conçu pour favoriser l'apprentissage et l'innovation, a été récompensé pour son excellence architecturale. Le bâtiment Decelles, connu affectueusement sous le nom de "Bunker" en raison de sa façade en béton et de l'absence de fenêtres, témoigne de l'histoire et de l'évolution de HEC Montréal depuis son inauguration par Robert Bourassa en 1970.
              </p>
 
              <h2>Un Engagement envers la Communauté et l'Inclusion</h2>
              <p>
              HEC Montréal s'engage à offrir un campus accueillant et respectueux pour tous, en mettant en œuvre des mesures pour favoriser l'équité, la diversité et l'inclusion. Cette vision d'inclusion et de durabilité fait du campus de HEC Montréal un lieu où les étudiants, le personnel et les professeurs peuvent s'épanouir tout en contribuant positivement à la société.
              </p>

              <p>
               En choisissant HEC Montréal, les étudiants s'inscrivent dans une tradition d'excellence, bénéficiant d'un environnement d'apprentissage qui prépare les leaders responsables de demain. L'innovation, la durabilité et l'inclusion sont les pierres angulaires de l'expérience HEC Montréal, reflétant l'engagement de l'école envers le développement d'une communauté globale et responsable.
              </p>

            </div>

        </div>
          <p>Etudes-Canada vous propose <a href='/home'>un guide complet </a>qui vous permet de tout savoir sur les procédures nécessaires pour partir étudier au canada: classement des universités, dates limites pour postuler, examens d'anglais requis, visas requis, délais de traitement et plus encore.</p>
        </div>
        
                     
        <BuySection />
        <Footer />
      </div>
    )
  }
  
  export default CampusHecMontreal