import './body.css';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const Body = () => {
  

  const Chapter = (props) => {
    const [isMinimized, setIsMinimized] = useState(true);
    return(

    <div className='chapter-box' onClick={() => setIsMinimized(!isMinimized)}>
      <div className='chapter-title'>
        <h1>{props.title}</h1>
        <FontAwesomeIcon icon={isMinimized ? faChevronDown : faChevronUp} color='black' />
      </div>
      {!isMinimized && (

        <div className='chapter-content'>
          <ul>
          {props.content.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
          </ul>
        </div>
      )}
    </div>
  );
}


  return (
    <div className='body section__padding' id='body'>
      <div className='body-title'>
        <h1>Contenu du guide</h1>
      </div>

      <Chapter 
        title="Chapitre 1 - Pourquoi le Canada?" 
        content={[
          "Quelles différences avec le système français?",
          "Combien coûte une année d'études au Canada?",
          "Le système d'éducation est-il vraiment meilleur?",
          "Aurais-je de meilleurs débouchés d'emploi?" 
        ]}
      />

      <Chapter 
        title="Chapitre 2 - Pré-requis en langue anglaise" 
        content={[
          "Quel niveau d'anglais dois-je avoir?",
          "Comment le faire reconnaitre?",
          "Quel examen d'anglais est le plus reconnu?",
          "Quelle est la note minimale requise en général?" 
        ]}
      />

      <Chapter 
        title="Chapitre 3 - Universités Canadiennes" 
        content={[
          "Comment sont classées les universités?",
          "Quels sont les pré-requis d'admission?",
          "Quelles écoles ont des programmes en français?",
          "Quelles sont les dates limites d'admission par école?" 
        ]}
      />

      <Chapter 
        title="Chapitre 4 - Les différents programmes d'études" 
        content={[
          "Comprendre le système de 'crédits' dans les universités",
          "Qu'est-ce qu'un Bachelor Canadien? Et un Master?",
          "Combien de temps durent les programmes?",
          "Sont-ils reconnus en France?",
        ]}
      />

      <Chapter 
        title="Chapitre 5 - Postuler à une école" 
        content={[
          "Quels documents faut-il prévoir?",
          "Quel est le montant des frais de scolarité par école?",
          "Quelle est la date limite?",
          "Combien de temps à l'avance faut-il s'y prendre?"
        ]}
      />

      <Chapter 
        title="Chapitre 6 - Bourses d'études" 
        content={[
          "A quelles bourses d'études puis-je prétendre?",
          "Où postuler?",
          "Quels montant puis-je recevoir?"
        ]}
      />
      
      <Chapter 
        title="Chapitre 7 - Demander un visa" 
        content={[
          "Quel visa faut-il demander?",
          "Quand faut-il lancer la demande?",
          "Quel est le délai de traitement?",
          "Puis-je rentrer sur le territoire Canadien en attendant la réponse?"
        ]}
      />

      <Chapter 
        title="Chapitre 8 - Souscrire une assurance" 
        content={[
          "Dois-je souscrire une assurance? Laquelle?",
          "Que doit-elle couvrir?",
          "Quelle compagnie d'assurance choisir?"
        ]}
      />

      <Chapter 
        title="Chapitre 9 - Entrée au Canada" 
        content={[
          "Que dois-je emmener avec moi?",
          "Quel est le processus de réception du visa lorsque je serai à la frontière?",
          "Cela vaut-il la peine d'aller en résidence étudiant?",
          "Comment trouver un bon logement, rapidement?",
          "Quelle banque et opérateur téléphonique choisir?"
        ]}
      />

    </div>
  )
}

export default Body