import React, { useEffect } from 'react';
import { Navbar, Footer} from '../containers';
import Article from '../components/article/article';



function DownloadSuccess() {

  useEffect(() => {
    // Vérifiez si gtag est déjà défini
    const gtag = window.gtag || function() { window.dataLayer.push(arguments); };

    // Ajoutez l'événement de suivi de conversion spécifique
    gtag('event', 'conversion', {
      'send_to': 'AW-11464455012/0HDDCKTk7YUZEOTm1toq',
      'transaction_id': '' // Ici vous pouvez passer un identifiant de transaction si disponible
    });
  }, []);


    return (
      <div className="download-confirmation">
        <Navbar />
        <Article 
          title="Merci!" 
          content={[
            "Le guide vous sera envoyé à l'adresse email fournie.",
            "Si vous souhaitez que le guide vous soit envoyé à une autre adresse, vous pouvez me contacter à hello@alexandresaussier.com",
            "",
            "Alexandre S., auteur du guide"
]}
        />          
        <Footer />
      </div>
    )
  }
  
  export default DownloadSuccess