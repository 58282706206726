import { Navbar, Footer} from '../containers';
import Article from '../components/article/article';


function Support() {

    return (
      <div className="Support">
        <Navbar />
        <Article 
          title="Support" 
          content={[

            "hello@alexandresaussier.com",

            
          ]}
        />          
        <Footer />
      </div>
    )
  }
  
  export default Support