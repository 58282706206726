import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import './navbar.css';
import Logo from '../../assets/etudes-canada-logo.png'

const Menu = () => (
  <>
    <p><a href='/home#header'>Guide</a></p>
    <p><a href='/home#body'>Contenu</a></p>
  </>
)

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);


  return (
    <div className='navbar' id='navbar'>   
      <div className='logo'>
        <a href='/home'>
          <img src={Logo} alt="Etudes-Canada" />
          <p>Etudes-Canada</p>
        </a>
        
        
      </div>
      
      <div className='navbar-right'>
        <div className='navbar-links'>
          <Menu />
        </div>

        <a href='#get-guide-button'>
          <button className="buy-button">
            Télécharger
          </button>
        </a>

        <div className='navbar-hamburger'>
          {toggleMenu
            ? <RiCloseLine color='#0039a6' size={27} onClick={() => setToggleMenu(false)} />
            : <RiMenu3Line color='#0039a6' size={27} onClick={() => setToggleMenu(true)} />
          }
          
          {toggleMenu && ( //If an only if togglemenu is True
            <div className='navbar-hamburger_container scale-up-center'>
              <div className='navbar-hamburger_container-links'>
                <Menu />
              </div>
            </div>
          )}
        </div>
      </div>
      
      
</div>
  )
}

export default Navbar