import { Navbar, Footer, BuySection} from '../../containers';
import Article from '../../components/article/article';
import './blog-post.css'
import { getAnalytics, logEvent } from 'firebase/analytics';

import { useEffect } from 'react';




function PréparerSonEnfant() {

  //To check how many people have viewed this page in Google Analytics
  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Préparez votre enfant pour les études au Canada',
    });
  }, []);

    return (
      <div className="blog-post">
        <title>Préparer son enfant aux études au Canada</title>

        <Navbar />
        <div className='blog-post-content'>
          <Article 
            title="Conseils pour les parents : Préparez votre enfant pour les études au Canada" 
            content={[

              "L'éducation au Canada est reconnue mondialement pour sa qualité. En tant que parent, préparer votre enfant pour des études au Canada peut être à la fois excitant et stressant. Voici quelques conseils pour faciliter cette transition.",
              "1. Comprendre le système éducatif canadien: Renseignez-vous sur le système universitaire canadien, qui diffère du système français. Les universités au Canada offrent divers programmes et options.",
              "2. Maîtriser la langue: Que ce soit en anglais ou en français, la maîtrise de la langue est essentielle. Encouragez votre enfant à suivre des cours de langue avant le départ.",

              "3. Gestion des démarches administratives: Les démarches pour obtenir un visa étudiant et l'admission dans les universités canadiennes peuvent être complexes. Commencez tôt et suivez attentivement les procédures.",

              "4. Planifier le budget: Étudier au Canada implique des coûts - frais de scolarité, logement, nourriture, etc. Planifiez un budget réaliste et renseignez-vous sur les bourses d'études disponibles.",

              "5. Santé et assurances: Vérifiez les exigences en matière d'assurance santé pour les étudiants internationaux au Canada.",

              "6. S'adapter à la culture canadienne: Le Canada est un pays multiculturel. Discutez avec votre enfant des différences culturelles et de l'importance de l'ouverture d'esprit.",

              "7. Sécurité et bien-être: Parlez de la sécurité et du bien-être personnel. Le Canada est un pays sûr, mais il est toujours important de rester vigilant.",

              "8. Gardez le contact: Grâce à la technologie, il est facile de rester en contact. Planifiez des appels réguliers pour soutenir votre enfant à distance.",

              "Conclusion:\nPréparer votre enfant pour les études au Canada est un processus qui demande planification et recherche. Avec ces conseils, vous pouvez contribuer à une expérience enrichissante et éducative pour votre enfant.",
              
            ]}
          />
          <p>Etudes-Canada vous propose <a href='/home'>un guide complet </a>qui vous permet de tout savoir sur les procédures nécessaires pour partir étudier au canada: classement des universités, dates limites pour postuler, examens d'anglais requis, visas requis, délais de traitement et plus encore.</p>
        </div>
        
               
        

        <BuySection />
        <Footer />
      </div>
    )
  }
  
  export default PréparerSonEnfant