import './author.css';
import photoAlex from '../../assets/alex_mcgill_2017.JPG'

const Author = () => {

    return(
        <div className='author_section '>
            <div className='text_photo_row section__padding'>
                <div className='author_text'>
                    <p>Salut, c'est Alex, auteur de ce guide.</p>
                    <p>En 2017, je suis parti étudier à Montréal. J'étais le premier de ma famille à partir étudier à l'étranger, et ni moi ni mes parents savions par où commencer.</p>
                    <p>J'ai passé de nombreuses heures sur internet à essayer de comprendre le processus: admissions, visas, dates, test d'anglais, etc.</p>
                    <p>Quelques années après la fin de mes études, je me suis dit que mon expérience pourrait rassurer et faire gagner du temps à tous ceux qui auraient comme projet de partir étudier au Canada.</p>
                    <p>Pour cela, j'ai rédigé un guide complet qui comprend exactement tout ce que vous devez savoir. Il vous fera gagner beaucoup de temps et vous épargnera du stress. </p>
                    <p>Et s'il te reste des questions après avoir reçu mon guide, je suis toujours joignable pour t'aider!</p>
                </div>
                <div className='author_picture'>
                    <img src={photoAlex} alt="Etudes Canada" />
                </div>
            </div>
        </div>
    )
}

export default Author

