import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home.js';
import Licenses from './pages/licenses.js';
import Support from './pages/support.js';
import PréparerSonEnfant from './pages/blog/préparer-son-enfant-aux-études-au-canada.js';
import TermsOfService from './pages/terms_of_service.js';
import GuideDesVisas from './pages/blog/Guide-des-visas.js';
import PrivacyPolicy from './pages/privacy_policy.js';
import PurchaseSuccess from './pages/purchase_success.js';
import DownloadSuccess from './pages/free_download_confirmation.js';
import DecouvrirHecMontreal from './pages/blog/etudier-hec-montreal.js';
import AvantagesHecMontreal from './pages/blog/avantages-hec-montreal.js';
import ReputationHecMontreal from './pages/blog/reputation-hec-montreal.js';
import FraisHecMontreal from './pages/blog/frais-hec-montreal.js';
import CampusHecMontreal from './pages/blog/campus-hec-montreal.js';

import './App.css';

<link href='https://fonts.googleapis.com/css?family=Lato:400,700' rel='stylesheet' type='text/css'/>




function App() {
  <script src="https://kit.fontawesome.com/c8898b9c43.js" crossorigin="anonymous"></script>
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/*' element={<Home />} />
          <Route path='/licenses' element={<Licenses />} />
          <Route path='/support' element={<Support />} />
          <Route path='/terms-of-service' element={<TermsOfService />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/purchase-success' element={<PurchaseSuccess />} />
          <Route path='/download-success' element={<DownloadSuccess />} />

          <Route path='/blog/préparer-son-enfant-aux-études-au-canada' element={<PréparerSonEnfant />} />
          <Route path='/blog/guide-des-visas' element={<GuideDesVisas />} />
          <Route path='/blog/decouvrir-hec-montreal' element={<DecouvrirHecMontreal />} />
          <Route path='/blog/avantages-hec-montreal' element={<AvantagesHecMontreal />} />
          <Route path='/blog/reputation-hec-montreal' element={<ReputationHecMontreal />} />
          <Route path='/blog/frais-hec-montreal' element={<FraisHecMontreal />} />
          <Route path='/blog/campus-hec-montreal' element={<CampusHecMontreal />} />

        </Routes>
      </BrowserRouter>
      
    </div>
  )
}

export default App