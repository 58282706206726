import { Navbar, Body, Header, Footer, Author, BuySection} from '../containers';



function Home() {

  return (
    <div className="Home">
          <Navbar />
          <Header />
          <Body />
          <BuySection />
          <Author />
          <Footer />      
    </div>
  )
}

export default Home