import './buy-section.css';
import people from '../../assets/people.png';
import ScreenshotDates from '../../assets/guide_screenshot_dates.png';
import ScreenshotBourses from '../../assets/guide_screenshot_bourses.png';
import ScreenshotCover from '../../assets/guide_screenshot_cover.png';

import { loadStripe } from '@stripe/stripe-js';
import { app } from '../../config/firebase.js'
import { getFunctions, httpsCallable } from 'firebase/functions';
import React, { useState } from 'react';
import 'firebase/firestore';
//import { doc, getDoc, getFirestore } from 'firebase/firestore';


const BuySection = () => {
  
  const [email, setEmail] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  
   // FOR STRIPE

  const handleGetReportClick = async () => {
    const functions = getFunctions(app);
    const createStripeCheckout = httpsCallable(functions, 'createStripeCheckout');
    
    const stripe = await loadStripe('pk_live_51NxxSZAvu92XwZMPec1CWTd1gHGixCGCEdKLM5uhTvLN1rixDalKzrIxop5DAs3yIsK4fkKxMWpcmRFlEqkrlKB400shw77nfU');
    // pk_test_51NxxSZAvu92XwZMPwTPPueeKEPcDGag1NUMQ9eX8wFFtiKVC2Tu4ua7ykJbnGaUGBFbZYOsUeUrkOLOeJBVp5YxZ001XsY0Kpv
    // pk_live_51NxxSZAvu92XwZMPec1CWTd1gHGixCGCEdKLM5uhTvLN1rixDalKzrIxop5DAs3yIsK4fkKxMWpcmRFlEqkrlKB400shw77nfU
    try {
      await createStripeCheckout()
      .then(response => {
        const sessionId = response.data.id;
        stripe.redirectToCheckout({sessionId: sessionId});
        
        const session = stripe.checkout.sessions.retrieve(sessionId);
        console.log(session.customerEmail);
        if (session.payment_status === 'paid') {
          // const customerEmail = session.customer_email;

          // Store in database 
        }
      });

    } catch (error) {
      console.error('Error:', error);
      // Handle the error appropriately
    }
  };
  
      //write a function to send an email to the user on Click of button
      /*const sendReportEmail = (userEmail) => {
        const functions = getFunctions(app); // Initialize Firebase Functions
        const sendEmail = httpsCallable(functions, 'sendEmail');

        // If the file is hosted online, provide the URL here
        // const fileUrl = "https://storage.cloud.google.com/canada-guide-3afa2.appspot.com/Guide_Etudes_Canada.pdf";
        // const fileUrl = "guide-etudes-canada.pdf";

        const emailData = {
          email: userEmail,
          // file: fileUrl,
        };

        sendEmail(emailData)
          .then((result) => {
            console.log(result);
            toggleModal();
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      };*/

  
    return (
      <div className='buy-section section__padding' id="get-guide-button">
        <div className='buy-section-background'>
          <div className='remaining-free'>
            <h2>Télécharge ton guide sur les études au Canada</h2>

          </div>
          <div className='perks'>
            <ul>
                <li>Accès au guide complet (32 pages) sur les études au Canada</li>
                <li>Pour les abonnés chatGPT Plus: Accès à un conseiller intelligent virtuel, pour répondre à toutes vos questions 24/7</li>
                <li>Accès au serveur Discord "étudiants français au Canada"</li>
            </ul>
          </div>

          <input 
          className='email-input'
          type="email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          placeholder="Entre ton email ici" 
          
          />
          
         
          <button className="style-button" onClick={() => handleGetReportClick()}>
            <p>Reçois ton guide par email pour 0.99€</p>
          </button> 
         
                    
        </div>
        
        
        <div className='reviews-section section__padding'>
          <div className='people-image-container'>
            <img src={people} alt='people' />
          </div>
          <p>34 personnes ont acheté ce guide aujourd'hui</p>
        </div>

        <div className='extracts-section'>
          <h1>Extraits</h1>
        
          <div className='guide-screenshots'>
            <img src={ScreenshotCover} alt="guide screenshot cover" width="400"/>
            <img src={ScreenshotDates} alt="guide screenshot deadlines" width="400"/>
            <img src={ScreenshotBourses} alt="guide screenshot courses" width="400"/>

          </div>
          
        </div>

        {isModalVisible && (
          <>
          <div className="backdrop" onClick={toggleModal}></div>
          <div className="download-success-modal">
            <h2>Merci d'avoir téléchargé le guide!</h2>
            <p>Un e-mail vous a été envoyé.</p>
            <p>Il contient également une invitation pour rejoindre notre communauté Discord.</p>
            <p>Si vous ne l'avez pas reçu, envoyez un mail à alex@etudes-canada.com</p>
            <button className="style-button" onClick={toggleModal}>Fermer</button>
          </div>
          </>
        )}


      </div>
    )
  }
  
  export default BuySection