//Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

//import { getAnalytics, logEvent } from 'firebase/analytics';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB_swpM8Aaxsvdgx-LjJCN3a5FGWqEcSUo",
  authDomain: "canada-guide-3afa2.firebaseapp.com",
  databaseURL: "https://canada-guide-3afa2-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "canada-guide-3afa2",
  storageBucket: "canada-guide-3afa2.appspot.com",
  messagingSenderId: "1029988547109",
  appId: "1:1029988547109:web:3a5d5f962dca3702daddcc",
  measurementId: "G-JPHM4BWB96"
  //  measurementId: "G-KJGJ5PTMCV"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
//export const firebase_analytics = getAnalytics(app);

