import { Navbar, Footer} from '../containers';
import Article from '../components/article/article';


function Licenses() {

    return (
      <div className="Licenses">
        <Navbar />
        <Article 
          title="Accord de Licence" 
          content={[

            "Accord de Licence d'Utilisateur Final pour Etudes-Canada.com",

            "Objet: Ce document constitue un accord légal entre vous (l'utilisateur) et Etudes-Canada.com pour l'achat et l'utilisation du guide numérique concernant les études au Canada.",

            "Licence: En achetant le guide, Etudes-Canada.com vous accorde une licence non exclusive et non transférable pour utiliser le guide à des fins personnelles et non commerciales.",

            "Restrictions: Il est interdit de copier, redistribuer, vendre, louer ou sous-licencier tout ou partie du guide. Le contenu est uniquement pour votre usage personnel.",

            "Propriété Intellectuelle: Tous les droits d'auteur et autres droits de propriété intellectuelle relatifs au guide appartiennent à Etudes-Canada.com.",

            "Limitation de Responsabilité: Etudes-Canada.com ne garantit pas l'exactitude complète des informations fournies et ne sera pas responsable des décisions prises sur la base de ce guide.",

            "Modification de l'Accord: Etudes-Canada.com se réserve le droit de modifier cet accord à tout moment, avec effet immédiat après la publication d'une version mise à jour sur le site web.",

            "Loi Applicable et Juridiction: Cet accord est régi par les lois du pays de résidence de l'utilisateur et tout litige sera soumis à la juridiction exclusive des tribunaux de ce pays."
          ]}
        />          
        <Footer />
      </div>
    )
  }
  
  export default Licenses